<template>
    <!-- site content -->
    <div class="main-page pt-3">
        <main class="main-container">
            <div class="container custom-container">
                <section>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <h3>Welcome {{ loggedInUser.name }}</h3>
                        </div>
                    </div>
                    <div class="row">
                        <single-board v-if="loggedInUser.superAdmin"/>
                        <single-board v-for="(item,key) in loggedInUser.companies"
                            :key="`single-company-board-${key}`"
                            :companyInfo="item"/>

                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3"
                             v-if="loggedInUser.invitations && loggedInUser.invitations.length > 0">
                            <div class="table-box p-3">
                                <div class="">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <h2 class="mb-1">You Have {{ loggedInUser.invitations.length }} Project Invitations</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="my-3">
                                    <div class="table-responsive">
                                        <table class="table table-striped table-hover project_invitation">
                                            <thead class="table-header">
                                            <tr>
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Project Name</th>
                                                <th scope="col">Project Leader</th>
                                                <th scope="col">Role</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody v-if="loggedInUser.invitations">
                                            <tr v-for="(project_invitation, index) in loggedInUser.invitations" :key="index">
                                                <td>{{ project_invitation.company_name }}</td>
                                                <td>{{ project_invitation.project_name }}</td>
                                                <td>{{ project_invitation.project_lead }}</td>
                                                <td>{{ project_invitation.role }}</td>
                                                <td>{{ project_invitation.start_date }}</td>
                                                <td>
                                                    <span v-if="project_invitation.invitation_status === 'Invited'" class="me-1"
                                                          data-toggle="tooltip" data-placement="top" title="Declined"
                                                          @click="changeInvitationStatus(index, 'Declined')">
                                                        <font-awesome-icon :icon="['fas', `minus-circle`]"></font-awesome-icon>
                                                    </span>
                                                    <span v-if="project_invitation.invitation_status === 'Invited'"
                                                          data-toggle="tooltip" data-placement="top" title="Accepted"
                                                          @click="changeInvitationStatus(index, 'Accepted')">
                                                        <font-awesome-icon :icon="['fas', `check-circle`]"></font-awesome-icon>
                                                    </span>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tbody v-else>
                                            <tr>
                                                <td colspan="6" class="text-center">No Data Found!</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </main>

        <BottomFooter></BottomFooter>
    </div>
    <!-- /site content end-->

</template>

<script>
    import {useStore} from "vuex";
    import {computed, onMounted} from "vue";
    import SingleBoard from "@/views/auth/board/SingleBoard";
    import HelperFunction from "@/common/helpers";
    import BottomFooter from "@/views/layouts/auth/common/BottomFooter";

    export default {
        name: "Board",
        components: {SingleBoard, BottomFooter},
        setup() {
            const store = useStore();
            const { storeFormData } = new HelperFunction()

            /** Computed Properties**/
            const loggedInUser = computed(() => store.getters.getProfile);
            /** Computed Properties end**/
            
            async function changeInvitationStatus(key, status) {
                const data = {
                    invitation_id: loggedInUser.value.invitations[key].invitation_id,
                    project_id: loggedInUser.value.invitations[key].project_id,
                    status: status
                };
                await storeFormData({'url': vueConfig.Dashboard.DashboardInvitationEndPoint}, data)
                    .then((response) => {
                        // console.error(response.data.data)
                        if (response.data.data != "Declined") {
                            loggedInUser.value.invitations.splice(key, 1);
                            localStorage.removeItem('user_info');
                            store.dispatch('userAction')
                        } else {
                            loggedInUser.value.invitations.splice(key, 1);
                            localStorage.removeItem('user_info');
                        }
                    })
            }

            return {
                loggedInUser, changeInvitationStatus
            }
        }
    }
</script>

<style scoped>

    .project_invitation tr td span {
        cursor: pointer;
        font-size: 20px;
    }

</style>